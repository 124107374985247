<!-- 志愿者管理表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @bulkDeletion="del"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!-- 联系方式 -->
      <div slot="contactWay" slot-scope="{ record }">
        {{ record.contactWay ? record.contactWay : "--" }}
      </div>
      <!-- 服务时长 -->
    </ax-table>
    <!-- 新增志愿者弹窗 -->
    <add-volunteer ref="volunteerInfo" @refsh="refsh"></add-volunteer>
    <!-- 志愿者详情弹窗 -->
    <watch-volunteer ref="volunteerInfoDetail" @refsh="refsh"></watch-volunteer>
    <!-- 选择团队弹窗 -->
    <add-team ref="teamInfo" @refsh="refsh"></add-team>
    <!-- 查看团队详情弹窗 -->
    <!-- <watch-team ref="teamInfoDetail" @refsh="refsh"></watch-team> -->
  </div>
</template>

<script>
import addVolunteer from "./addVolunteer.vue";
import watchVolunteer from "./watchVolunteer.vue";
import addTeam from "./addTeam.vue";
// import WatchTeam from './watchTeam.vue';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "residentName",
    options: { placeholder: "请输入志愿者姓名" },
    formItem: {},
    col: { span: 6 },
  },
  // 级联选择器
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";

export default {
  components: { addVolunteer, watchVolunteer, addTeam },
  //import引入的组件需要注入到对象中才能使用
  // components: { addTeam, WatchTeam},
  data() {
    //这里存放数据
    return {
      api,
      // 表格数据
      columns: this.$ax.tools.initColumn(
        [
          // {
          //   title: "id",
          //   dataIndex: "id",
          //   ellipsis: true,
          //   width: 150,
          // },
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 70,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 180,
          },
          {
            title: "联系方式",
            dataIndex: "contactWay",
            ellipsis: false,
            width: 130,
          },
          {
            title: "服务时长(h)",
            dataIndex: "serviceDuration",
            ellipsis: false,
            width: 100,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 180,
          },
        ],
        true, //去掉序号
        {
          width: 220,
          // fixed: "right",
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除志愿者?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "添加团队",
                  name: "addTeam",
                  type: "#389E0D",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增志愿者" },
        { name: "bulkDeletion", text: "批量删除", type: "#F95A5A" },
        { name: "export", text: "导出", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.volunteerInfoList,
      // dataSourceParams: { auditStatus: ['0','1'].join(',') }, //待审核和审核通过
      dataSourceParams: { auditStatus: "1" }, // 审核通过
      // dataSourceParams: {}, //pc需要根据state来动态改变参数
      selectedPeopleList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取所属网格数据（楼栋 单元 楼层 户号）
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            { options: { options, allowClear: true, placeholder: "请选择",changeOnSelect:true } }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
        auditStatus: "1",
      };
      const res = await api.volunteerInfoList(obj);
      this.$refs.tableBox.getDataSource(obj);
      this.selectedPeopleList = res.data.records;
      // console.log(this.selectedPeopleList, "表格已有数据");
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          record.title = "编辑志愿者信息";
          this.$refs.volunteerInfo.openModal(record);
          break;
        // 详情
        case "watch":
          record.title = "志愿者详情";
          this.$refs.volunteerInfoDetail.openModal(record);
          break;
        // 详情
        case "addTeam":
          record.title = "选择团队";
          this.$refs.teamInfo.openModal(record);
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteVolunteerInfoById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.refsh();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
      this.getTableListData();
    },
    add() {
      this.$refs.volunteerInfo.openModal({
        title: "新增志愿者",
        selectedPeopleList: this.selectedPeopleList,
      });
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteVolunteerInfoByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.owningGrid();
    this.getTableListData();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.red {
  background-color: #fff1f0;
  color: #cf1322;
  border-radius: 4px;
  border: #fff1f0;
}
.yellow {
  background-color: #fffbe6;
  color: #d48806;
  border-radius: 4px;
  border: #fffbe6;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>